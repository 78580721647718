/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

// fonts
import "@fontsource/roboto"
import "@fontsource/roboto-slab/700.css"

// styles
import "./layout.css"
import "../../styles/global.css"

// components
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, headerLang, footerLang, langType }) => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "logo.webp" }) {
        childImageSharp {
          fixed(width: 50, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      title: site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header
        langType={langType}
        lang={headerLang}
        siteTitle={data.title.siteMetadata?.title || `Title`}
        fixed={data.logoImage?.childImageSharp?.fixed}
      />
      <main className="min-h-screen">{children}</main>
      <Footer
        langType={langType}
        lang={footerLang}
        siteTitle={data.title.siteMetadata?.title || `Title`}
        fixed={data.logoImage?.childImageSharp?.fixed}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerLang: PropTypes.object,
  footerLang: PropTypes.object,
}

export default Layout
