import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

// gsap
import gsap from "gsap"

// icons
import { CgMenuGridR } from "react-icons/cg"

const Header = ({ fixed, lang, langType }) => {
  const mobileMenu = useRef(null)
  const mobileMenuBg = useRef(null)
  const menuTL = gsap.timeline({ paused: true, ease: "slow(0.7, 0.7, false)" })
  useEffect(() => {
    menuTL
      .to(mobileMenu.current, {
        top: 0,
        duration: 0.4,
      })
      .to(mobileMenuBg.current, { height: "100vh", duration: 0.5 }, "-=.2")
  }, [menuTL])

  const playMenu = () => {
    menuTL.play()
  }

  const reverseMenu = () => {
    menuTL.reverse()
  }

  return (
    <>
      <header className="absolute w-full z-10 bg-darkGreen text-white top-0">
        <div className="relative container flex justify-between items-center h-24 font-body max-w-1024 px-2x">
          <Link to={langType === "en" ? "/" : "/es/"}>
            <div className="flex items-center">
              <div className="w-10">
                <Img className="h-full" fixed={fixed} loading="eager" />
              </div>
              <p className="m-0 flex flex-col space-y-0 uppercase text-sm font-bold ml-4">
                <span>Energy</span>
                <span>Efficient</span>
                <span>Engineering</span>
              </p>
            </div>
          </Link>
          <div className="text-gray-100 flex items-center mobile:hidden">
            <h1 className="nav-item px-6 hero:px-4 border-none">
              <Link
                to={
                  langType === "en"
                    ? "/building-commissioning"
                    : "/es/building-commissioning"
                }
              >
                {lang?.commissioning[0]} <br /> {lang?.commissioning[1]}
              </Link>
            </h1>
            <h1 className="nav-item px-6 hero:px-4">
              <Link
                to={
                  langType === "en" ? "/energy-modeling" : "/es/energy-modeling"
                }
              >
                {lang?.modeling[0]} <br /> {lang?.modeling[1]}
              </Link>
            </h1>
            <h1 className="nav-item px-6 hero:px-4">
              <Link
                to={
                  langType === "en"
                    ? "/energy-retrofits"
                    : "/es/energy-retrofits"
                }
              >
                {lang?.retrofits[0]} <br /> {lang?.retrofits[1]}
              </Link>
            </h1>
            <h1 className="nav-item px-6 hero:px-4">
              <Link
                to={langType === "en" ? "/cfd-modeling" : "/es/cfd-modeling"}
              >
                {lang?.cfd[0]} <br /> {lang?.cfd[1]}
              </Link>
            </h1>
            <h1 className="nav-item px-6 hero:px-4">
              <Link to={langType === "en" ? "/projects" : "/es/projects"}>
                {lang?.projects[0]} <br /> {lang?.projects[1]}
              </Link>
            </h1>
            <Link to={langType === "en" ? "/" : "/es/"}>
              <span className="text-lightGreen border-lightGreen border px-4 py-1 text-xs m-0 ml-4 duration-300 hover:opacity-50">
                {lang?.contact}
              </span>
            </Link>
          </div>
          <button
            className="text-white text-4xl bg-darkerBlue fixed right-10 rounded desktop:hidden shadow-lg"
            onClick={() => playMenu()}
          >
            <CgMenuGridR />
          </button>
        </div>
      </header>
      <div
        ref={mobileMenu}
        className="p-10 text-center w-full space-y-8 bg-darkerBlue fixed flex flex-col text-white text-2xl font-bold z-20 font-heading"
        aria-hidden="true"
        style={{ top: "-1000px" }}
      >
        <button onClick={() => reverseMenu()}>
          <Link to={langType === "en" ? "/" : "/es/"}>Home</Link>
        </button>
        <button onClick={() => reverseMenu()}>
          <Link
            to={
              langType === "en"
                ? "/building-commissioning"
                : "/es/building-commissioning"
            }
          >
            {lang?.commissioning[0]} {lang?.commissioning[1]}
          </Link>
        </button>
        <button onClick={() => reverseMenu()}>
          <Link
            to={langType === "en" ? "/energy-modeling" : "/es/energy-modeling"}
          >
            {lang?.modeling[0]} {lang?.modeling[1]}
          </Link>
        </button>
        <button onClick={() => reverseMenu()}>
          <Link
            to={
              langType === "en" ? "/energy-retrofits" : "/es/energy-retrofits"
            }
          >
            {lang?.retrofits[0]} {lang?.retrofits[1]}
          </Link>
        </button>
        <button onClick={() => reverseMenu()}>
          <Link to={langType === "en" ? "/cfd-modeling" : "/es/cfd-modeling"}>
            {lang?.cfd[0]} {lang?.cfd[1]}
          </Link>
        </button>
        <button onClick={() => reverseMenu()}>
          <Link to={langType === "en" ? "/projects" : "/es/projects"}>
            {lang?.projects[0]} {lang?.projects[1]}
          </Link>
        </button>
        <button onClick={() => reverseMenu()}>
          <Link
            to={langType === "en" ? "/" : "/es/"}
            className="text-lightGreen"
          >
            {lang?.contact}
          </Link>
        </button>
      </div>
      <div
        ref={mobileMenuBg}
        role="button"
        className="bg-black bg-opacity-75 w-screen fixed z-10"
        onClick={() => reverseMenu()}
        aria-hidden="true"
        style={{}}
      />
    </>
  )
}

export default Header
