import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const Footer = ({ fixed, lang, langType }) => {
  return (
    <footer
      className="font-bold text-white bg-darkGreen flex items-center h-48"
      style={{ zIndex: "-10" }}
    >
      <div className="container flex justify-between items-start font-body max-w-1024 px-2x">
        <div className="flex space-x-6 items-start mobile:justify-between mobile:w-full">
          <Link to={langType === "en" ? "/" : "/es/"}>
            <div className="flex items-center space-x-2 mr-6">
              <div className="transform scale-75">
                <Img fixed={fixed} />
              </div>

              <h1 className="text-xs m-0">
                Energy Efficient <br />
                Engineering
              </h1>
            </div>
          </Link>
          <div className="flex flex-col itmes-start space-y-2 justify-start text-xs">
            <p className="m-0 capitalize">{lang?.contact}</p>
            <p className="m-0">
              <a href="mailto:marcoburgos@energy-ee.com">
                marcoburgos@energy-ee.com
              </a>
            </p>
            <p className="m-0">Miami Beach, Florida 33140</p>
          </div>
        </div>
        <div className="mobile:hidden text-gray-100 cappitalize flex flex-col items-end space-y-2 font-bold text-xs">
          <div className="space-x-4">
            <Link
              to={
                langType === "en"
                  ? "/building-commissioning"
                  : "/es/building-commissioning"
              }
            >
              {lang?.navItems[0]}
            </Link>
            <Link
              to={
                langType === "en" ? "/energy-modeling" : "/es/energy-modeling"
              }
            >
              {lang?.navItems[1]}
            </Link>
            <Link
              to={
                langType === "en" ? "/energy-retrofits" : "/es/energy-retrofits"
              }
            >
              {lang?.navItems[2]}
            </Link>
          </div>
          <div className="space-x-4">
            <Link to={langType === "en" ? "/cfd-modeling" : "/es/cfd-modeling"}>
              {lang?.navItems[3]}
            </Link>
            <Link to={langType === "en" ? "/projects" : "/es/projects"}>
              {lang?.navItems[4]}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
